<template>
  <BaseListItem
      :item="item"
      :type="'well'"
      :depth="depth"
      :child="child"
      :shower="'show'"
      :always-on="true"
  >
    <template v-slot:title>
      {{ item.name }}
    </template>

    <template v-slot:sub-list>
          <SampleItem
              v-for="well of child"
              :key="well.id"
              :item="well"
              :depth="depth + 1"
          ></SampleItem>
    </template>
  </BaseListItem>
</template>

<script>
import BaseListItem from "@/components/list/BaseListItem";
import SampleItem from "@/components/list/SampleItem";
import {mapGetters} from "vuex";

export default {
  name: "WellItem",
  props: ['item', 'depth'],
  components: {
    BaseListItem,
    SampleItem,
  },
  computed: {
    ...mapGetters(['wellSamples']),
    child: function () {
      return this.wellSamples(this.item.id)
    }
  },
}
</script>
<template>
  <BaseListItem
    :item="item"
    :type="'thin-section'"
    :depth="depth"
    :child="child"
  >
    <template v-slot:title>
      {{ item.name }}
    </template>

    <template v-slot:sub-list>
      <ThinSectionPlotItem
        v-for="well of child"
        :key="well.id"
        :item="well"
        :depth="depth + 1"
      ></ThinSectionPlotItem>
    </template>
  </BaseListItem>
</template>

<script>
import BaseListItem from "@/components/list/BaseListItem";
import ThinSectionPlotItem from "@/components/list/ThinSectionPlotItem";
import {mapGetters} from "vuex";

export default {
  name: "ThinSectionItem",
  props: ['item', 'depth'],
  components: {
    BaseListItem,
    ThinSectionPlotItem,
  },
  computed: {
    ...mapGetters(['thinSectionPlots']),
    child: function () {
      return this.thinSectionPlots(this.item.id)
    }
  },
}
</script>
<template>
  <span>
    <v-icon
        @click.stop="collapse(item)"
        dense
    >
      {{ iconName }}
    </v-icon>
  </span>
</template>

<script>
export default {
  name: "DynamicIcon",
  props: [
    'item',
    'child',
    'alwaysOn',
    'shower',
  ],
  data() {
    return {
      icon: 'mdi-chevron-down'
    }
  },
  methods: {
    collapse() {
      this.$emit('collapse')
    }
  },
  computed: {
    iconName: function () {
      if ((this.alwaysOn || this.child && this.child.length > 0) && this.shower) {
        return 'mdi-chevron-up'
      } else if ((this.alwaysOn || this.child && this.child.length > 0) && !this.shower) {
        return 'mdi-chevron-down'
      } else {
        return 'mdi-minus'
      }
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <BaseListItem
      :item="item"
      :type="'tectonic'"
      :depth="depth"
      :child="child"
  >
    <template v-slot:title>
      {{ item.name }}
    </template>

    <template v-slot:sub-list>
      <DepositItem
          v-for="deposit of child"
          :key="deposit.id"
          :item="deposit"
          :depth="depth + 1"
      ></DepositItem>
    </template>
  </BaseListItem>
</template>

<script>
import BaseListItem from "@/components/list/BaseListItem";
import DepositItem from "@/components/list/DepositItem";
import {mapGetters} from "vuex";

export default {
  name: "TectonicItem",
  props: ['item', 'depth'],
  components: {
    DepositItem,
    BaseListItem,
  },
  computed: {
    ...mapGetters(['tectonicDeposits']),
    child() {
      return this.tectonicDeposits(this.item.id)
    }
  },
}
</script>
<template>
  <li class="list-item">
    <p
        @click="navigate(item)"
        @contextmenu="selectItem($event)"
        class="list-item-text"
        :class="[`depth${depth}`, {
          'list-link': hasChild,
          'active': isActive
        }]"
    >
      <DynamicIcon
          :shower="item[shower]"
          :child="child"
          :always-on="alwaysOn"
          @collapse="collapse()"
      ></DynamicIcon>
      <slot name="title"></slot>
    </p>
    <div v-if="this.item[this.shower]">
      <slot name="sub-list">
      </slot>
    </div>
  </li>
</template>

<script>
import DynamicIcon from "@/components/list/DynamicIcon";
import {mapMutations} from "vuex";

export default {
  name: "BaseListItem",
  components: {
    DynamicIcon
  },
  props: {
    item: {},
    type: {},
    depth: {},
    child: {},
    shower: {
      default: 'show'
    },
    subList: {
      default: false
    },
    alwaysOn: {
      default: false
    },
    isContextClickable: {
      default: true
    }
  },
  computed: {
    isActive() {
      return this.$route.path === this.item.link && !this.subList
    },
    hasChild: function () {
      return this.child != null && this.child.length > 0
    }
  },
  methods: {
    ...mapMutations(['SET_CHOSEN_ITEMS']),
    async collapse() {
      this.item[this.shower] = !this.item[this.shower]
    },
    async navigate() {
      if (this.$router.currentRoute.path !== this.item.link) {
        await this.$router.push(this.item.link)
      }
    },
    selectItem(event) {
      console.log(event)
      event.preventDefault()
      if (!this.isContextClickable) {
        return
      }
      this.SET_CHOSEN_ITEMS({
        item: this.item,
        type: this.type
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
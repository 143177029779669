<template>
  <BaseListItem
      :item="item"
      :type="'deposit'"
      :depth="depth"
      :child="child"
  >
    <template v-slot:title>
      {{ item.name }}
    </template>

    <template v-slot:sub-list>
      <WellItem
          v-for="well of child"
          :key="well.id"
          :item="well"
          :depth="depth + 1"
      ></WellItem>
    </template>
  </BaseListItem>
</template>

<script>
import BaseListItem from "@/components/list/BaseListItem";
import WellItem from "@/components/list/WellItem";
import {mapGetters} from "vuex";

export default {
  name: "DepositItem",
  props: ['item', 'depth'],
  components: {
    BaseListItem,
    WellItem
  },
  computed: {
    ...mapGetters(['depositWells']),
    child() {
      return this.depositWells(this.item.id)
    }
  },
}
</script>
<template>
  <BaseListItem
      :item="item"
      :type="'thin-section-plot'"
      :depth="depth"
      :child="child"
      :is-context-clickable="false"
  >
    <template v-slot:title>
      {{ item.name }}
    </template>

    <template v-slot:sub-list>
    </template>
  </BaseListItem>
</template>

<script>
import BaseListItem from "@/components/list/BaseListItem";

export default {
  name: "ThinSectionPlotItem",
  props: ['item', 'depth'],
  components: {
    BaseListItem,
  },
  computed: {
    child: function () {
      return this.item.thin_section_plots
    }
  },
}
</script>